<h3 class="panel-header">Aktív munkamenetek</h3>

<div *ngIf="loading">
    <i class="fa fa-spinner fa-spin"></i> Adatok betöltése...
</div>

<div class="session-list" *ngIf="!loading && list.length > 0">
    <a class="session-element" *ngFor="let item of list" [routerLink]="['/session/',item.id]">
        <span class="plate">{{item.plate}}</span>
        <span>{{item.createdAt}}</span>
    </a>
</div>