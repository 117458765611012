import { Component, OnDestroy, OnInit } from "@angular/core";
import { SessionService } from "../../services/session.service";
import { ISession } from "src/app/interfaces/session.interface";
import { Subscription } from "rxjs";

@Component({
  selector: "app-session-list",
  templateUrl: "./session-list.component.html",
  styleUrls: ["./session-list.component.less"],
})
export class SessionListComponent implements OnInit, OnDestroy {

  public loading: boolean = false;
  public list: ISession[] = [];
  public sessionSubscription: Subscription = new Subscription();

  constructor(private readonly sessionService: SessionService) { }

  ngOnDestroy(): void {
    this.sessionSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loading = true;
    this.sessionSubscription = this.sessionService.getSessions().subscribe({
      next: (sessions: ISession[]) => { this.list = sessions; this.loading = false; },
      error: (error) => { console.error(error); this.loading = false; }
    })
  }
}
