import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SessionListComponent } from "./components/session-list/session-list.component";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { SessionItemComponent } from './components/session-item/session-item.component';

@NgModule({
  declarations: [
    SessionListComponent,
    SessionItemComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class SessionModule { }
