import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user-logout',
  templateUrl: './user-logout.component.html',
  styleUrls: ['./user-logout.component.less']
})
export class UserLogoutComponent implements OnInit, OnDestroy {

  private isAuthenticaedSubscription: Subject<boolean> = new Subject<any>();

  constructor(private readonly authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.authService.destroySession();
    this.authService.getIsAuthenticatedObservable().pipe(takeUntil(this.isAuthenticaedSubscription)).subscribe({
      next: () => this.router.navigate(['/']),
      error: (e) => console.log(e)
    })

  }

  ngOnDestroy(): void {
    this.isAuthenticaedSubscription.next(true);
    this.isAuthenticaedSubscription.complete();
  }

}
