export interface ISessionProduct {
  id: number;
  sessionId: number;
  goodId: number;
  quantity: number;
  totalPrice: number;
  unitPrice: number;
  unitVat: number;
  vat: number;
  title: string;
  category: string;
  position: string;
  sku: string;
}

export enum WheelPosition {
  'FRONT_LEFT' = 'FRONT_LEFT',
  'FRONT_RIGHT' = 'FRONT_RIGHT',
  'REAR_LEFT' = 'REAR_LEFT',
  'REAR_RIGHT' = 'REAR_RIGHT',
  'REAR_CENTER' = 'REAR_CENTER'
}

export const wheelPositionShortMap: Record<WheelPosition, string> = {
  [WheelPosition.FRONT_LEFT]: 'FL',
  [WheelPosition.FRONT_RIGHT]: 'FR',
  [WheelPosition.REAR_LEFT]: 'RL',
  [WheelPosition.REAR_CENTER]: 'RC',
  [WheelPosition.REAR_RIGHT]: 'RR'
}

export const wheelPositionShortMapReversed: Record<string, WheelPosition> = {
  'FL': WheelPosition.FRONT_LEFT,
  'FR': WheelPosition.FRONT_RIGHT,
  'RL': WheelPosition.REAR_LEFT,
  'RC': WheelPosition.REAR_CENTER,
  'RR': WheelPosition.REAR_RIGHT
}

export enum SessionType {
  "SERVICE" = "SERVICE",
  "PURCHASE" = "PURCHASE",
  "SCHEDULE" = "SCHEDULE",
}

export enum SessionState {
  "DRAFT" = "DRAFT",
  "ACTIVE" = "ACTIVE",
  "CLOSED" = "CLOSED",
  "SCHEDULED" = "SCHEDULED",
}

export enum Gate {
  'DEFAULT' = 0,
  'EXAMINATION' = 3,
  'SERVICE' = 4,
  'WASH' = 5
}

export interface ISessionAttachment {
  id: number;
  sessionId: number;
  attachmentName: string;
  attachmentType: string;
  imageType: string;
  issueType: string;
  affectedWheel: string;
  createdAt: string;
  updatedAt: string;
}

export interface ISession {
  id?: number;
  phone: string;
  email: string;
  name: string;
  city: string;
  zipcode: string;
  address: string;
  taxnumber: string;
  plate: string;
  createdAt?: string;
  updatedAt?: string;
  totalPrice: number;
  totalCount: number;
  sessionState: SessionState;
  sessionType: SessionType;
  hash: string;
  products: Array<ISessionProduct>;
  invoiceNumber: string;
  invoiceLink: string;
  hunterLink: string;
  maximumEffortInMinutes?: number;
  gate: number;
  paymentMethod: string;
  vehicleManufacturedYear?: number;
  billingFormat: string;
  billingMethod: string;
  readyToSymbol: boolean;
  milage: number | undefined;
}

export interface SessionToPresent {
  sessionId: number;
  section: string;
}