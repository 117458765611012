<input style="display:none;" #cameraInput type="file" accept="image/*" capture="camera" (change)="upload($event)">
<div *ngIf="session">
    <div>
        <div style="padding:15px 0;">
            <span style="font-weight: bold;display: block;">{{session.plate}}</span>
            <span style="display: block;font-size: 13px;margin-top:5px;">{{session.createdAt}}</span>
        </div>
    </div>
    <div>
        <div>
            <button (click)="openModalWindow()" class="create-photo-btn"><i class="fa fa-camera"></i> Fénykép
                csatolása</button>
        </div>
    </div>
    <div>
        <h3>Csatolmányok</h3>
        <div *ngIf="loading">
            <i class="fa fa-spinner fa-spin"></i> Adatok betöltése...
        </div>        
        <div class="attachments" *ngIf="!loading">
            <div *ngFor="let item of attachments">
                <table>
                    <tr>
                        <td>
                            <img class="thumbnail" src="{{uploadsFolderUrl}}/{{item.attachmentName}}" />
                        </td>
                        <td>
                            {{i18n[item.imageType]}} - {{i18n[item.issueType]}} - {{i18n[item.affectedWheel]}}
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>
</div>

<div class="modal attach-modal-window" *ngIf="modalOpened">
    <div class="modal-content modal-content-narrow">
        <div class="modal-header">
            <h3>Fénykép készítése</h3>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body">
            <div class="modal-form-row">
                <label for="image-type-selector">Fénykép típusa</label>
                <select name="image-type-selector" id="image-type-selector" [(ngModel)]="imageShootModel.imageType">
                    <option value="{{imageTypes.CHASSISNUMBER}}">{{i18n[imageTypes.CHASSISNUMBER]}}</option>
                    <option value="{{imageTypes.LICENSEPLATE}}">{{i18n[imageTypes.LICENSEPLATE]}}</option>
                    <option value="{{imageTypes.DISCOVERED_ISSUE}}">{{i18n[imageTypes.DISCOVERED_ISSUE]}}</option>
                </select>
            </div>
            <div class="modal-form-row">
                <label for="issue-type-selector">Hiba jellege</label>
                <select name="issue-type-selector" id="issue-type-selector" [(ngModel)]="imageShootModel.issueType">
                    <option value="{{issueTypes.CARRIAGE}}">{{i18n[issueTypes.CARRIAGE]}}</option>
                    <option value="{{issueTypes.BREAKS}}">{{i18n[issueTypes.BREAKS]}}</option>
                    <option value="{{issueTypes.UNKNOWN}}">{{i18n[issueTypes.UNKNOWN]}}</option>
                </select>
            </div>
            <div class="modal-form-row">
                <label for="wheel-selector">A hiba ezt a kereket érinti</label>
                <select name="wheel-selector" id="wheel-selector" [(ngModel)]="imageShootModel.affectedWheel">
                    <option value="{{wheelPositions.FRONT_LEFT}}">{{i18n[wheelPositions.FRONT_LEFT]}}</option>
                    <option value="{{wheelPositions.FRONT_RIGHT}}">{{i18n[wheelPositions.FRONT_RIGHT]}}</option>
                    <option value="{{wheelPositions.REAR_LEFT}}">{{i18n[wheelPositions.REAR_LEFT]}}</option>
                    <option value="{{wheelPositions.REAR_RIGHT}}">{{i18n[wheelPositions.REAR_RIGHT]}}</option>
                </select>
            </div>
            <div class="modal-form-row">
                <button (click)="openCamera()" class="create-photo-btn"><i class="fa fa-camera"></i> Fénykép
                    feltöltése</button>
            </div>
        </div>
    </div>
</div>