import { Routes } from "@angular/router";
import { UserLoginComponent } from "./components/user-login/user-login.component";
import { UserLogoutComponent } from "./components/user-logout/user-logout.component";
import { UserPasswordForgotComponent } from "./components/user-password-forgot/user-password-forgot.component";
import { UserPasswordResetComponent } from "./components/user-password-reset/user-password-reset.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";

export const getUserModuleRoutes = (ActivationGuard: any): Routes => {
  return [
    {
      path: "user/profile",
      component: UserProfileComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Személyes beállítások"  },
    },
    {
      path: "user/login",
      component: UserLoginComponent,
      data: { showNavigation: false },
    },
    {
      path: "user/password-forgot",
      component: UserPasswordForgotComponent,
      data: { showNavigation: false },
    },
    {
      path: "user/password-reset/:hash",
      component: UserPasswordResetComponent,
      data: { showNavigation: false },
    },
    {
      path: "user/logout",
      component: UserLogoutComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: false },
    },
  ];
};
