import { Routes } from "@angular/router";
import { SessionListComponent } from "./components/session-list/session-list.component";
import { SessionItemComponent } from "./components/session-item/session-item.component";

export const getSessionModuleRoutes = (ActivationGuard: any): Routes => {
  return [
    {
      path: "session/list",
      component: SessionListComponent,
      canActivate: [ActivationGuard]
    },
    {
      path: "session/:id",
      component: SessionItemComponent,
      canActivate: [ActivationGuard]
    },
  ];
};
