import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ISession, ISessionAttachment } from "./session.interface";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(private readonly http: HttpClient) { }

  public getSessions(): Observable<ISession[]> {
    return this.http.get<ISession[]>(`${environment.api.hostname}/session`);
  }

  public getSession(id: number): Observable<ISession> {
    return this.http.get<ISession>(`${environment.api.hostname}/session/${id}`);
  }

  public getSessionAttachments(id: number): Observable<ISessionAttachment[]> {
    return this.http.get<ISessionAttachment[]>(`${environment.api.hostname}/session/attachments/${id}`);
  }

  public uploadSessionAttachment(sessionId: number, attachment: File, meta: { imageType: string, issueType: string, affectedWheel: string }): Observable<{ uri: string }> {

    const formData = new FormData();
    formData.append('file', attachment);
    formData.append('sessionId', sessionId.toString());
    formData.append('imageType', meta.imageType);
    formData.append('issueType', meta.issueType);
    formData.append('affectedWheel', meta.affectedWheel);

    return this.http.post<{ uri: string }>(`${environment.api.hostname}/session/attach-image`, formData);
  }
}
