import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { getUserModuleRoutes } from './modules/user/user.routes';
import { getSessionModuleRoutes } from './modules/session/session.routes';
import { SessionListComponent } from './modules/session/components/session-list/session-list.component';
import { AuthGuardService as AuthGuard } from "./modules/user/services/auth.guard.service";

const routes: Routes = [
  ...getUserModuleRoutes(AuthGuard),
  ...getSessionModuleRoutes(AuthGuard),
  {
    path: "",
    component: SessionListComponent,
    canActivate: [AuthGuard],
    data: { showNavigation: true },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
