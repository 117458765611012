import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { SessionService } from '../../services/session.service';
import { ISession, ISessionAttachment, WheelPosition } from '../../services/session.interface';
import { ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';
import { environment } from 'src/environments/environment';

enum IssueType {
  'BREAKS' = 'BREAKS',
  'CARRIAGE' = 'CARRIAGE',
  'UNKNOWN' = 'UNKNOWN'
}
enum ImageType {
  'CHASSISNUMBER' = 'CHASSISNUMBER',
  'LICENSEPLATE' = 'LICENSEPLATE',
  'DISCOVERED_ISSUE' = 'DISCOVERED_ISSUE'
}

@Component({
  selector: 'app-session-item',
  templateUrl: './session-item.component.html',
  styleUrls: ['./session-item.component.less']
})
export class SessionItemComponent implements OnInit {

  public loading = false;

  public uploadsFolderUrl = environment.api.uploadsUrl;

  public session: ISession | undefined;
  public attachments: ISessionAttachment[] = [];
  public modalOpened: boolean = false;

  public issueTypes = IssueType;
  public imageTypes = ImageType;
  public wheelPositions = WheelPosition;

  public imageShootModel: { affectedWheel: WheelPosition | undefined, issueType: IssueType | undefined, imageType: ImageType | undefined } = {
    affectedWheel: undefined,
    imageType: undefined,
    issueType: undefined
  };

  public i18n: Record<string, string> = {
    [WheelPosition.FRONT_LEFT]: 'Bal első',
    [WheelPosition.FRONT_RIGHT]: 'Jobb első',
    [WheelPosition.REAR_LEFT]: 'Bal hátsó',
    [WheelPosition.REAR_RIGHT]: 'Jobb hátsó',
    [ImageType.CHASSISNUMBER]: 'Alvázszám',
    [ImageType.DISCOVERED_ISSUE]: 'Felfedezett hiba',
    [ImageType.LICENSEPLATE]: 'Forgalmi rendszám',
    [IssueType.BREAKS]: 'Fékek',
    [IssueType.CARRIAGE]: 'Futómű',
    [IssueType.UNKNOWN]: 'Egyéb'
  };

  constructor(private readonly sessionService: SessionService, private readonly route: ActivatedRoute, private readonly ms: MessageService) { }

  public imageFileToUpload: any | undefined;

  @ViewChild("cameraInput", { static: true }) cameraInput: | ElementRef<HTMLInputElement>;

  public upload(event: Event): void {

    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length > 0 && this.session && this.session.id) {
      const file = target.files[0];
      this.sessionService.uploadSessionAttachment(this.session.id, file, {
        imageType: this.imageShootModel.imageType || '',
        issueType: this.imageShootModel.issueType || '',
        affectedWheel: this.imageShootModel.affectedWheel || '',
      }).subscribe({
        next: (result: { uri: string }) => {
          this.ms.createMessage('Feltöltés sikeres volt', 'success');
          this.readAttachments();
          this.closeModalWindow();
        },
        error: (error: any) => console.error(error)
      })
    }

  }

  private async getRouteParams(): Promise<Params> {
    return firstValueFrom(this.route.params);
  }

  public readAttachments(): void {

    if (!this.session) { return; }
    const { id } = this.session;
    if (!id) { return; }

    this.loading = true;

    this.sessionService.getSessionAttachments(id).subscribe({
      next: (attachments: ISessionAttachment[]) => { this.attachments = attachments; this.loading = false; },
      error: (error) => {console.error(error); this.loading = false;}
    });
  }

  public async ngOnInit(): Promise<void> {
    const { id } = await this.getRouteParams();
    this.sessionService.getSession(id).subscribe({
      next: (session: ISession) => { this.session = session; this.readAttachments(); },
      error: (error) => console.error(error)
    });

  }

  private escapeKeyHandler = this.escapeHandler.bind(this);
  private escapeHandler(e: KeyboardEvent): void {
    if (e.key === "Escape") {
      this.closeModalWindow();
    }
  }

  public openModalWindow(): void {
    this.modalOpened = true;
    
    this.imageFileToUpload = null;
    this.imageShootModel.affectedWheel = undefined;
    this.imageShootModel.imageType = undefined;
    this.imageShootModel.issueType = undefined;
    
    document.addEventListener("keyup", this.escapeKeyHandler);
  }

  public closeModalWindow(): void {
    this.modalOpened = false;
    document.removeEventListener("keyup", this.escapeKeyHandler);
  }

  public openCamera(): void {
    this.cameraInput.nativeElement.click();
  }
}
